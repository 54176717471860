import cookie from 'js-cookie';
import { parseCookies, destroyCookie } from 'nookies';

import { COOKIES, REFRESH_URL_ABSOLUTE } from '@ov-id/auth/constants';
import { COOKIE_DOMAIN, LOGIN_URL } from '../config/constants';
import { isTokenExpired, setAccessToken } from './accessToken';
import { setCustomerAccessToken } from './shopifyToken';

export const clearCookies = (): void => {
  setAccessToken('');
  setCustomerAccessToken('');
  // Clear the program cookie
  // The Program cookie is also used by OV ID to clear on logout
  // The names are also defined in the ov-shopify customers app utils.js file
  const opts = { expires: 7, domain: COOKIE_DOMAIN };
  cookie.remove(COOKIES.PROGRAM, opts);
  cookie.remove(COOKIES.PROGRAM_LOADED, opts);
};

export const refreshClientAuth = async (): Promise<void> => {
  const request = await fetch(REFRESH_URL_ABSOLUTE, {
    method: 'POST',
    credentials: 'include',
  });
  const response = await request.json();
  if (!response.ok) {
    return;
  }
  setAccessToken(response.accessToken);
};

export const serverSideAuth = async (context): Promise<any> => {
  const cooks = parseCookies(context);
  const refreshToken = cooks[COOKIES.REFRESH];
  const accessToken = cooks[COOKIES.JWT];
  let serverAccessToken = accessToken;

  const redirect = () => {
    destroyCookie(context, COOKIES.REFRESH, { domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN });
    context.res.writeHead(302, {
      Location: LOGIN_URL,
    });
    context.res.end();
    return;
  };

  if (!refreshToken) {
    redirect();
    return;
  }

  try {
    // Refresh the tokens if either one has expired
    if (isTokenExpired(accessToken)) {
      const request = await fetch(REFRESH_URL_ABSOLUTE, {
        method: 'POST',
        credentials: 'include',
        headers: {
          cookie: `${COOKIES.REFRESH}=${refreshToken}`,
        },
      });
      const response = await request.json();
      if (!response.ok) {
        redirect();
        return;
      }
      serverAccessToken = response.accessToken;
    }
  } catch (exc) {
    redirect();
    return;
  }
  return { serverAccessToken };
};

export const serverSideNoAuth = async (context): Promise<void> => {
  const cooks = parseCookies(context);
  const refreshToken = cooks[COOKIES.REFRESH];

  const redirect = () => {
    context.res.writeHead(302, {
      Location: '/',
    });
    context.res.end();
  };

  if (refreshToken) {
    redirect();
  }
};
